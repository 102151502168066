import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./astrologer.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { deleteBanner_api, getBanners_api, statusUpdate_banner_api } from "../api/banner";
import { deleteAstrologer_api, getAstrologerTranscation_api, getAstrologers_api, invoiceListing } from "../api/astrologer";

const InvoiceListing = () => {
    const navigate = useNavigate();
    const location = useLocation()
    console.log("location", location.state)
    const [isLoading, setisLoading] = useState(false);
    const [allInvoices, setallInvoices] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    useEffect(() => {
        fetchAstroAllInvoiceFunc();
    }, [pageCount, search]);
    console.log("allInvoices", allInvoices)

    async function fetchAstroAllInvoiceFunc(data) {
        setisLoading(true);
        try {
            const temp = {
                id: location.state?.data?.id,
                page: pageCount,
                limit: 10,
                search: search.trim(),
            };
            let res = await invoiceListing(temp);
            if (res.data.status) {
                setallInvoices(res?.data?.data);
                setpageLength(res?.data?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteastroFunc() {
        setisLoading(true);
        let temp = {
            id: deleteId,
        };
        try {
            let res = await deleteAstrologer_api(temp);
            if (res.data.status) {
                setisLoading(false);
                setdeletePopup(false);
                fetchAstroAllInvoiceFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Invoices - {location.state?.data?.id}</h3>
                    </div>
                    <div className={s["user-list-search"]}>
                    </div>
                </div>
                <div className="beat_left">
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">ID</StyledTableCell>
                            <StyledTableCell align="center">Amount</StyledTableCell>
                            <StyledTableCell align="center">Amount Type</StyledTableCell>
                            {/* <StyledTableCell align="center">Note</StyledTableCell> */}
                            <StyledTableCell align="center">Created</StyledTableCell>
                            <StyledTableCell align="center">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {allInvoices?.map((row, index) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                    ID : {row?.id} <br />
                                    {/* Name : {row?.name} <br />
                                    Email :{row?.email}   <br />
                                    Number : {row?.phone} <br /> */}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.invoice_amount}</StyledTableCell>
                                <StyledTableCell align="center">{row.txn_type == "credit" ? "Deposit" : "Withdraw"} </StyledTableCell>
                                <StyledTableCell align="center">{row?.created_at?.slice(0, 10)}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <div className={s["astrologer-action-btn"]} style={{ flexWrap: "wrap" }}  >
                                        <span
                                            onClick={() =>
                                                navigate("/invoice", {
                                                    state: {
                                                        data: row,
                                                    },
                                                })
                                            }
                                        >
                                            View
                                        </span>
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {allInvoices?.length <= 0 && <DataNotFound />}
                {/* {allInvoices?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )} */}
            </div>

            {/* <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to delete {currentGroup.name}?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
                            Cancel
                        </div>
                        <div className={s.employee_gl_popup_del} onClick={() => deleteastroFunc()}>
                            Delete
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog> */}
            <Loder loading={isLoading} />
        </div>
    );
};

export default InvoiceListing;
