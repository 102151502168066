import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./offer.module.css";
import { useNavigate } from "react-router-dom";
import { delete_admin_api, fetchAllAdmin, status_admin_api } from "../api/admin";
import Loder from "../../Loder/Loder";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { deleteOffer_api, getOffers_api, updateOfferIsDefault_api } from "../api/offerAPI";

const OfferListing = () => {
  const navigate = useNavigate();
  const [isloading, setisLoading] = useState(false);
  const [allOffers, setallOffers] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState(null);
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  useEffect(() => {
    fetchAllOffersFunc();
  }, [pageCount, search]);
  // console.log("allOffers", allOffers)

  const fetchAllOffersFunc = async (data) => {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getOffers_api(temp);
      if (res.data.status) {
        setallOffers(res.data.data);
        setpageLength(res?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function deleteBrandFunc() {
    setisLoading(true);
    let res = await deleteOffer_api(deleteId);
    if (res.data.status) {
      setdeletePopup(false);
      setisLoading(false);
      fetchAllOffersFunc();
      notificationHandler({ type: "success", msg: res.data.message });
    } else {
      setisLoading(false);
      notificationHandler({ type: "danger", msg: res.data.message });
    }
  }

  const makeDefaultFunc = async (id) => {
    setisLoading(true);

    let res = await updateOfferIsDefault_api(id);
    if (res.data.status) {
      notificationHandler({ type: "success", msg: res.data.message });
      setisLoading(false);
      fetchAllOffersFunc();
    } else {
      notificationHandler({ type: "success", msg: res.data.message });
      console.log("Some Error!")
    }
  }

  const admin_status = async (data) => {
    console.log(data.id);
    setisLoading(true);
    let temp = {
      id: data.id,
      status: data.status == true ? false : true,
    };
    try {
      let res = await status_admin_api(temp);
      console.log(res);

      if (res.data.status) {
        setisLoading(false);
        fetchAllOffersFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));


  return (
    <div className="container">
      <div className="beat_heading">
        <div className={s["user-list-title"]}>
          <h3>Offers List</h3>
        </div>
        <div className={s["user-list-heading"]}>
          <div className={s["user-list-title"]}>
            <div className="beat_left">
              <div
                className={s["title"]}
                onClick={() =>
                  navigate("/add_offer", {
                    state: {
                      pagetype: "Add",
                    },
                  })
                }
              >
                <IoMdAdd /> Offer
              </div>
            </div>
          </div>
          <div className={s["user-list-search"]}>
            {/* <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search name..."
              />
            </div> */}
            {/* <div className={s["filter-btn"]}>
              <span style={{ paddingRight: "2px" }}>
                <BiFilter size={20} />
              </span>
              Filter
            </div> */}
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell >Priority</StyledTableCell>
              <StyledTableCell align="center">Recharge Amount</StyledTableCell>
              <StyledTableCell align="center">Offer Amount</StyledTableCell>
              {/* <StyledTableCell align="center">Offer Type</StyledTableCell> */}
              <StyledTableCell align="center">Validity (in Days)</StyledTableCell>
              <StyledTableCell align="center">Start Date</StyledTableCell>
              <StyledTableCell align="center">End Date</StyledTableCell>
              <StyledTableCell align="center">Use Count</StyledTableCell>
              <StyledTableCell align="center">Default</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allOffers && allOffers?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell >{row.priority}</StyledTableCell>
                <StyledTableCell align="center">{row.base_amt} </StyledTableCell>
                <StyledTableCell align="center">{`${row.offer_amt}${row.offer_type === "fix" ? "Rs" : "%"}`}</StyledTableCell>
                {/* <StyledTableCell align="center">{row.offer_type} </StyledTableCell> */}
                <StyledTableCell align="center">{row.validity}</StyledTableCell>
                <StyledTableCell align="center">{`${row.start_date?.slice(0, 10)} ${row.start_date?.split("T")[1]?.slice(0, 5)}`}</StyledTableCell>
                <StyledTableCell align="center">{`${row.end_date?.slice(0, 10)} ${row.end_date?.split("T")[1]?.slice(0, 5)}`}</StyledTableCell>
                <StyledTableCell align="center">{row.use_count == "-1" ? "multiple" : row.use_count}</StyledTableCell>
                <StyledTableCell align="center">
                  <div style={{ cursor: "pointer" }} onClick={() => makeDefaultFunc(row.id)} className={`${row.is_default === true ? s.active_admin : s.inactive_admin}`}>
                    {`${row.is_default === true ? "Yes" : "No"}`}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div className={s["astrologer-action-btn"]}>
                    <span
                      onClick={() => {
                        navigate("/add_offer", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                          },
                        })
                      }}
                    >
                      Edit
                    </span>
                    <span
                      onClick={() => {
                        setdeletePopup(true);
                        setcurrentGroup(row);
                        setdeleteId(row.id);
                      }}
                    >
                      Delete
                    </span>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allOffers && allOffers.length <= 0 && <DataNotFound />}
        {/* {allOffers && allOffers?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
          </div>
        )} */}
      </div>

      <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to delete {currentGroup.coupon_code}?</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
              Cancel
            </div>
            <div className={s.employee_gl_popup_del} onClick={() => deleteBrandFunc()}>
              Delete
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
};

export default OfferListing;
