import React from "react";
import Admin from "./pages/Admin";
import Adminadd from "./pages/Adminadd";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/profile";
import UserAdd from "./pages/UserAdd";
import Users from "./pages/Users";
import AddLanguage from "./components/Language/AddLanguage";
import RollList from "./pages/RollList";
import AddRolePage from "./pages/AddRolePage";
import { SkillList } from "./pages/SkillList";
import AddSkill from "./components/Skill/AddSkill";
import Language from "./pages/Language";
import Category from "./pages/Category";
import AddCategoryPage from "./pages/AddCategoryPage";
import Banner from "./pages/Banner";
import AddBanner from "./pages/AddBanner";
import Testimonial from "./pages/Testimonial";
import AddTestimonial from "./pages/AddTestimonial";
import Astrologer from "./pages/Astrologer";
import AddAstrologer from "./pages/AddAstrologer";
import AddWallet from "./components/Users/UserWallet";
import NewsListing from "./components/News/NewsListing";
import AddNews from "./components/News/AddNews";
import OfferListing from "./components/Offers/OfferListing";
import AddOffer from "./components/Offers/AddOffer";
import Wallet from "./components/Wallet/Wallet";
import AstrologerWallet from "./components/Astrologer/AstrologerWallet";
import AstrologerAddWallet from "./components/Astrologer/AstrologerAddWallet";
import UserWallet from "./components/Users/UserWallet";
import UserAddWallet from "./components/Users/UserAddWallet";
import AstrologerGallery from "./components/Astrologer/AstrologerGallery";
import AstrologerGalleryAdd from "./components/Astrologer/AstrologerGalleryAdd";
import AstrologerSettlement from "./components/Astrologer/AstrologerSettlement";
import ViewInvoice from "./components/Astrologer/ViewInvoice";
import InvoiceListing from "./components/Astrologer/InvoiceListing";

const routeArray = [
  // { params: undefined, component: <Home1 /> },
  { params: "admin", component: <Admin /> },
  { params: "role", component: <RollList /> },
  { params: "dashboard", component: <Dashboard /> },
  { params: "adminadd", component: <Adminadd /> },
  { params: "add-role", component: <AddRolePage /> },
  { params: "profile", component: <Profile /> },
  { params: "skill-list", component: <SkillList /> },
  { params: "add-skill", component: <AddSkill /> },
  { params: "language", component: <Language /> },
  { params: "add-language", component: <AddLanguage /> },
  { params: "category-list", component: <Category /> },
  { params: "add-category", component: <AddCategoryPage /> },
  { params: "banner-list", component: <Banner /> },
  { params: "add-banner", component: <AddBanner /> },
  { params: "testimonial-list", component: <Testimonial /> },
  { params: "testimonial-add", component: <AddTestimonial /> },
  
  // Astrologer
  { params: "astrologer-list", component: <Astrologer /> },
  { params: "astrologer-add", component: <AddAstrologer /> },
  { params: "astrologer-wallet", component: <AstrologerWallet /> },
  { params: "astrologer-add-wallet", component: <AstrologerAddWallet /> },
  { params: "astrologer-gallery", component: <AstrologerGallery /> },
  { params: "astrologer-gallery-add", component: <AstrologerGalleryAdd /> },
  { params: "astrologer-settlement", component: <AstrologerSettlement /> },
  { params: "invoice", component: <ViewInvoice /> },
  { params: "astrologer-invoices", component: <InvoiceListing /> },
  
  // User
  { params: "users", component: <Users /> },
  { params: "useradd", component: <UserAdd /> },
  { params: "user-wallet", component: <UserWallet /> },
  { params: "user-add-wallet", component: <UserAddWallet /> },

  // Wallet
  { params: "wallet", component: <Wallet /> },

  // News
  { params: "news_list", component: <NewsListing /> },
  { params: "add_news", component: <AddNews /> },

  // Offer
  { params: "offers_list", component: <OfferListing /> },
  { params: "add_offer", component: <AddOffer /> },
];

export default routeArray;