import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const getCategories = getBaseUrl() + "admin/getCategories";
const status_update_categories = getBaseUrl() + "admin/updateCategory";
const deleteCategory = getBaseUrl() + "admin/deleteCategory";
const addCategory = getBaseUrl() + "admin/addCategory";
const updateCategory = getBaseUrl() + "admin/updateCategory";

export const updateCategory_api = async (data) => {
  let config = {
    method: "patch",
    url: updateCategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addCategory_api = async (data) => {
  let config = {
    method: "post",
    url: addCategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteCategory_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteCategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const status_update_categories_api = async (data) => {
  let config = {
    method: "PATCH",
    url: status_update_categories,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCategories_api = async (data) => {
  let config = {
    method: "get",
    url: getCategories + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
