import React from "react";
import SubAdminList from "../components/Admin/SubAdminList";

const Admin = () => {
  return (
    <>
      <SubAdminList />
    </>
  );
};

export default Admin;
