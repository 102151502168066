import React, { useState, useEffect } from "react";
import s from "./astrologer.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, CircularProgress, Grid } from "@mui/material";
import { addBanner_api, updateBanner_api } from "../api/banner";
import { getLanguages_api } from "../api/language";
import Select from "react-select";
import { getCategories_api } from "../api/category";
import { fetchAllSkills } from "../api/skill";
import { addAstrologer_api, updateAstrologer_api } from "../api/astrologer";
import { FaEye } from "react-icons/fa";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { BsEyeSlash, BsEye } from "react-icons/bs";

const AstrologerAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [type, settype] = useState("");
  const [isloading, setisloading] = useState(false);

  const [profile_image, setprofile_image] = useState(null);
  const [aadhar_image, setaadhar_image] = useState(null);
  const [pan_image, setpan_image] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [alllanguage, setalllanguage] = useState([]);
  const [languageId, setlanguageId] = useState(null);
  const [allCategories, setallCategories] = useState([]);
  const [categorieId, setcategorieId] = useState(null);
  const [allSkills, setallSkills] = useState([]);
  const [skillsId, setskillsId] = useState(null);

  const [showAccNum, setshowAccNum] = useState(false);
  const [showCAccNum, setshowCAccNum] = useState(false);

  const pagetype = location.state.pagetype;
  console.log(location.state)

  useEffect(() => {
    fetchalllistingdataFunc();
  }, []);

  useEffect(() => {
    if (pagetype == "Edit") {
      const {
        is_chat,
        aadhar_no,
        address,
        bank_account_holder_name,
        bank_account_no,
        bank_ifsc,
        bank_name,
        bio,
        commision,
        display_name,
        dob,
        email,
        experience,
        gender,
        gst,
        is_busy,
        is_chat_online,
        is_expert,
        is_question,
        is_video_call,
        is_video_online,
        is_voice_call,
        is_voice_online,
        name,
        pan_no,
        password,
        per_min_chat,
        per_min_chat_mrp,
        per_min_chat_usd,
        per_min_video_call,
        per_min_video_call_usd,
        per_min_voice_call,
        per_min_voice_call_usd,
        per_question_price,
        per_question_price_usd,
        phone,
        phone2,
        pincode,
        categories,
        status,
        order_count,
        qualification,
        state,
        tds_rate,
      } = location?.state?.data;
      setformdata({
        ...formdata,
        name: name,
        display_name: display_name,
        email: email,
        phone: phone,
        phone2: phone2,
        status: status,
        password: password,
        experience: experience,
        is_expert: is_expert,
        bio: bio,
        dob: dob,
        gender: gender,
        aadhar_no: aadhar_no,
        pan_no: pan_no,
        is_delete: "",
        is_busy: is_busy,
        languages: "",
        categories: "",
        skills: "",
        bank_name: bank_name,
        bank_account_type: "",
        bank_account_holder_name: bank_account_holder_name,
        bank_account_no: bank_account_no,
        c_bank_account_no: bank_account_no,
        bank_ifsc: bank_ifsc,
        devicetoken: "",
        deviceid: "",
        devicetype: "",
        address: address,
        country_id: "",
        state_id: "",
        city_id: "",
        pincode: pincode,
        wallet: "",
        commision: commision,
        per_min_chat: per_min_chat,
        per_min_chat_mrp: per_min_chat_mrp,
        per_min_voice_call: per_min_voice_call,
        per_min_video_call: per_min_video_call,
        per_question_price: per_question_price,
        per_min_chat_usd: per_min_chat_usd,
        per_min_voice_call_usd: per_min_voice_call_usd,
        per_min_video_call_usd: per_min_video_call_usd,
        per_question_price_usd: per_question_price_usd,
        is_question: is_question,
        is_chat: is_chat,
        is_voice_call: is_voice_call,
        is_video_call: is_video_call,
        is_chat_online: is_chat_online,
        is_voice_online: is_voice_online,
        is_video_online: is_video_online,
        is_chat: is_chat,
        is_voice_call: is_voice_call,
        is_video_call: is_video_call,
        is_chat_online: is_chat_online,
        is_voice_online: is_voice_online,
        is_video_online: is_video_online,
        gst: gst,
        rating: "",
        order_count: order_count,
        qualification,
        state,
        tds_rate,
      });
      console.log(location);
    }
  }, [location]);

  useEffect(() => {
    if (pagetype == "Edit") {
      const astroCategory = location.state.data.categories.split(",");
      const astroskills = location.state.data.skills.split(",");
      const astroslanguages = location.state.data.languages.split(",");

      const getcategoriesID = allCategories.filter((cat) => {
        return astroCategory.includes(String(cat.value));
      });
      setcategorieId(getcategoriesID);

      const getskillID = allSkills.filter((cat) => {
        return astroskills.includes(String(cat.value));
      });
      setskillsId(getskillID);

      const getlanguageID = alllanguage.filter((cat) => {
        return astroslanguages.includes(String(cat.value));
      });
      setlanguageId(getlanguageID);
    }
  }, [allCategories, allSkills, alllanguage]);

  const [formdata, setformdata] = useState({
    name: "",
    display_name: "",
    email: "",
    phone: "",
    phone2: "",
    status: "true",
    password: "",
    experience: "",
    is_expert: false,
    bio: "",
    dob: "",
    gender: "male",
    aadhar_no: "",
    pan_no: "",
    is_delete: "",
    is_busy: "",
    languages: "",
    categories: "",
    skills: "",
    bank_name: "",
    bank_account_type: "",
    bank_account_holder_name: "",
    bank_account_no: "",
    c_bank_account_no: "",
    bank_ifsc: "",
    devicetoken: "",
    deviceid: "",
    devicetype: "",
    address: "",
    country_id: "",
    state_id: "",
    state: "",
    tds_rate: "",
    city_id: "",
    pincode: "",
    wallet: "",
    commision: "",
    per_min_chat: 0,
    per_min_chat_mrp: 0,
    per_min_voice_call: "",
    per_min_video_call: "",
    per_question_price: "",
    per_min_chat_usd: "",
    per_min_voice_call_usd: "",
    per_min_video_call_usd: "",
    per_question_price_usd: "",
    is_question: false,
    is_chat: false,
    is_voice_call: false,
    is_video_call: false,
    is_chat_online: false,
    is_voice_online: false,
    is_video_online: false,
    gst: "",
    rating: "",
    order_count: "",
    qualification: "",
    base_consultation: "",
  });

  const formdataFun = (e) => {
    if (
      e.target.name === "is_chat" ||
      e.target.name === "is_voice_call" ||
      e.target.name === "is_video_call" ||
      e.target.name === "is_chat_online" ||
      e.target.name === "is_voice_online" ||
      e.target.name === "is_video_online" ||
      e.target.name === "is_expert" ||
      e.target.name === "is_question"
    ) {
      setformdata({ ...formdata, [e.target.name]: e.target.checked });
      return;
    }
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };

  async function fetchalllistingdataFunc() {
    try {
      const temp = {
        page: 1,
        limit: 9999,
        search: "",
      };
      let res = await getLanguages_api(temp);
      let res2 = await getCategories_api(temp);
      let res3 = await fetchAllSkills(temp);
      if (res.data.status) {
        let language = res?.data?.data.languages.map((item) => {
          item = { value: item.id, label: item.lang_name };
          return item;
        });
        setalllanguage(language);
      }
      if (res2.data.status) {
        let categorie = res2?.data?.data.categories.map((item) => {
          item = { value: item.id, label: item.category_name };
          return item;
        });
        setallCategories(categorie);
      }
      if (res3.data.status) {
        let Skill = res3?.data?.data.skills.map((item) => {
          item = { value: item.id, label: item.skill_name };
          return item;
        });
        setallSkills(Skill);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (selected) => {
    setlanguageId(selected);
  };
  const handleChange2 = (selected) => {
    setcategorieId(selected);
  };
  const handleChange3 = (selected) => {
    setskillsId(selected);
  };

  const addastrologer_function = async () => {
    if (isloading) return;
    if (!Number(formdata.per_min_chat_mrp)) return notificationHandler({ type: "danger", msg: "Astrologer Chat MRP Price is required!" });
    if (!Number(formdata.per_min_chat)) return notificationHandler({ type: "danger", msg: "Astrologer Chat SP Price is required!" });
    if (Number(formdata.per_min_chat_mrp) < Number(formdata.per_min_chat)) return notificationHandler({ type: "danger", msg: "Astrologer Chat Price MRP Price Should Be Greater Then Astrologer Chat Price SP Price" });
    if (formdata.bank_account_no !== formdata.c_bank_account_no) return notificationHandler({ type: "danger", msg: "Account Number didn't Matched!" });

    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("name", formdata.name);
      fd.append("display_name", formdata.display_name);
      fd.append("email", formdata.email);
      fd.append("phone", formdata.phone);
      fd.append("password", formdata.password);
      fd.append("phone2", formdata.phone2);
      fd.append("status", formdata.status);
      fd.append("experience", formdata.experience);
      fd.append("is_expert", formdata.is_expert);
      fd.append("bio", formdata.bio);
      fd.append("profile_image", profile_image);
      fd.append("dob", formdata.dob);
      fd.append("gender", formdata.gender);
      fd.append("aadhar_no", formdata.aadhar_no);
      fd.append("aadhar_image", aadhar_image);
      fd.append("pan_no", formdata.pan_no);
      fd.append("pan_image", pan_image);
      fd.append("is_delete", formdata.is_delete);
      fd.append("is_busy", formdata.is_busy);
      fd.append("languages", languageId ? languageId.map((item) => item?.value)?.toString() : "");
      fd.append("categories", categorieId ? categorieId.map((item) => item?.value)?.toString() : "");
      fd.append("skills", skillsId ? skillsId.map((item) => item?.value)?.toString() : "");
      fd.append("bank_name", formdata.bank_name);
      fd.append("bank_account_type", formdata.bank_account_type);
      fd.append("bank_account_holder_name", formdata.bank_account_holder_name);
      fd.append("bank_account_no", formdata.bank_account_no);
      fd.append("bank_ifsc", formdata.bank_ifsc);
      fd.append("devicetoken", formdata.devicetoken);
      fd.append("deviceid", formdata.deviceid);
      fd.append("devicetype", formdata.devicetype);
      fd.append("address", formdata.address);
      fd.append("country_id", formdata.country_id);
      fd.append("state_id", formdata.state_id);
      fd.append("state", formdata.state);
      fd.append("tds_rate", formdata.tds_rate);
      fd.append("city_id", formdata.city_id);
      fd.append("pincode", formdata.pincode);
      fd.append("wallet", formdata.wallet);
      fd.append("commision", formdata.commision);
      fd.append("per_min_chat", formdata.per_min_chat || formdata.per_min_chat_mrp);
      fd.append("per_min_chat_mrp", formdata.per_min_chat_mrp);
      fd.append("per_min_voice_call", formdata.per_min_voice_call);
      fd.append("per_min_video_call", formdata.per_min_video_call);
      fd.append("per_question_price", formdata.per_question_price);
      fd.append("per_min_chat_usd", formdata.per_min_chat_usd);
      fd.append("per_min_voice_call_usd", formdata.per_min_voice_call_usd);
      fd.append("per_question_price_usd", formdata.per_question_price_usd);
      fd.append("is_question", formdata.is_question);
      fd.append("is_chat", formdata.is_chat);
      fd.append("is_chat_online", formdata.is_chat_online);
      fd.append("is_voice_call", formdata.is_voice_call);
      fd.append("is_video_call", formdata.is_video_call);
      fd.append("is_video_online", formdata.is_video_online);
      fd.append("gst", formdata.gst);
      fd.append("rating", formdata.rating);
      fd.append("order_count", formdata.order_count);
      // fd.append("qualification", formdata.qualification);
      fd.append("qualif", formdata.qualification);
      setisloading(true);
      let res = await addAstrologer_api(fd);
      if (res.data.status) {
        navigate("/astrologer-list");
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
        console.log("error");
      }
      setisloading(false);
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("id", location.state.data.id);
      fd.append("name", formdata.name);
      fd.append("display_name", formdata.display_name);
      fd.append("email", formdata.email);
      fd.append("phone", formdata.phone);
      fd.append("password", formdata.password);
      fd.append("phone2", formdata.phone2);
      fd.append("status", formdata.status);
      fd.append("experience", formdata.experience);
      fd.append("is_expert", formdata.is_expert);
      fd.append("bio", formdata.bio);
      fd.append("profile_image", profile_image);
      fd.append("dob", formdata.dob);
      fd.append("gender", formdata.gender);
      fd.append("aadhar_no", formdata.aadhar_no);
      fd.append("aadhar_image", aadhar_image);
      fd.append("pan_no", formdata.pan_no);
      fd.append("pan_image", pan_image);
      fd.append("is_delete", formdata.is_delete);
      fd.append("is_busy", formdata.is_busy);
      fd.append("languages", languageId ? languageId.map((item) => item?.value)?.toString() : "");
      fd.append("categories", categorieId ? categorieId.map((item) => item?.value)?.toString() : "");
      fd.append("skills", skillsId ? skillsId.map((item) => item?.value)?.toString() : "");
      fd.append("bank_name", formdata.bank_name);
      fd.append("bank_account_type", formdata.bank_account_type);
      fd.append("bank_account_holder_name", formdata.bank_account_holder_name);
      fd.append("bank_account_no", formdata.bank_account_no);
      fd.append("bank_ifsc", formdata.bank_ifsc);
      fd.append("devicetoken", formdata.devicetoken);
      fd.append("deviceid", formdata.deviceid);
      fd.append("devicetype", formdata.devicetype);
      fd.append("address", formdata.address);
      fd.append("country_id", formdata.country_id);
      fd.append("state_id", formdata.state_id);
      fd.append("state", formdata.state);
      fd.append("tds_rate", formdata.tds_rate);
      fd.append("city_id", formdata.city_id);
      fd.append("pincode", formdata.pincode);
      fd.append("wallet", formdata.wallet);
      fd.append("commision", formdata.commision);
      fd.append("per_min_chat", formdata.per_min_chat);
      fd.append("per_min_chat_mrp", formdata.per_min_chat_mrp);
      fd.append("per_min_voice_call", formdata.per_min_voice_call);
      fd.append("per_min_video_call", formdata.per_min_video_call);
      fd.append("per_question_price", formdata.per_question_price);
      fd.append("per_min_chat_usd", formdata.per_min_chat_usd);
      fd.append("per_min_voice_call_usd", formdata.per_min_voice_call_usd);
      fd.append("per_question_price_usd", formdata.per_question_price_usd);
      fd.append("is_question", formdata.is_question);
      fd.append("is_chat", formdata.is_chat);
      fd.append("is_chat_online", formdata.is_chat_online);
      fd.append("is_voice_call", formdata.is_voice_call);
      fd.append("is_video_call", formdata.is_video_call);
      fd.append("is_video_online", formdata.is_video_online);
      fd.append("gst", formdata.gst);
      fd.append("rating", formdata.rating);
      fd.append("order_count", formdata.order_count);
      // fd.append("qualification", formdata.qualification);
      fd.append("qualif", formdata.qualification);

      setisloading(true);
      let res = await updateAstrologer_api(fd);
      if (res.data.status) {
        navigate("/astrologer-list");
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
      setisloading(false);
    }
  };

  const states_list = [
    {
      name: 'Andhra Pradesh',
      value: 'andhra_pradesh',
    },

    {
      name: 'Arunachal Pradesh',
      value: 'arunachal_pradesh',
    },

    {
      name: 'Assam',
      value: 'assam',
    },

    {
      name: 'Bihar',
      value: 'bihar',
    },

    {
      name: 'Chhattisgarh',
      value: 'chhattisgarh',
    },

    {
      name: 'Goa',
      value: 'goa',
    },

    {
      name: 'Gujarat',
      value: 'gujarat',
    },

    {
      name: 'Haryana',
      value: 'haryana',
    },

    {
      name: 'Himachal Pradesh',
      value: 'himachal_pradesh',
    },

    {
      name: 'Jammu & Kashmir',
      value: 'jammu_&_kashmir',
    },

    {
      name: 'Jharkhand',
      value: 'jharkhand',
    },

    {
      name: 'Karnataka',
      value: 'karnataka',
    },

    {
      name: 'Kerala',
      value: 'kerala',
    },

    {
      name: 'Madhya Pradesh',
      value: 'madhya_pradesh',
    },

    {
      name: 'Maharashtra',
      value: 'maharashtra',
    },

    {
      name: 'Manipur',
      value: 'manipur',
    },

    {
      name: 'Meghalaya',
      value: 'meghalaya',
    },

    {
      name: 'Mizoram',
      value: 'mizoram',
    },

    {
      name: 'Nagaland',
      value: 'nagaland',
    },

    {
      name: 'Odisha',
      value: 'odisha',
    },

    {
      name: 'Punjab',
      value: 'punjab',
    },

    {
      name: 'Rajasthan',
      value: 'rajasthan',
    },

    {
      name: 'Sikkim',
      value: 'sikkim',
    },

    {
      name: 'Tamilnadu',
      value: 'tamilnadu',
    },

    {
      name: 'Telangana',
      value: 'telangana',
    },

    {
      name: 'Tripura',
      value: 'tripura',
    },

    {
      name: 'Uttar Pradesh',
      value: 'uttar_pradesh',
    },

    {
      name: 'Uttarakhand',
      value: 'uttarakhand',
    },

    {
      name: 'West Bengal',
      value: 'west_bengal',
    },
    {
      name: 'Andaman & Nicobar Islands',
      value: 'andaman_nicobar_islands',
    },
    {
      name: 'Chandigarh',
      value: 'chandigarh',
    },
    {
      name: 'Dadra & Nagar Haveli and Daman & Diu',
      value: 'dadra_nagar_haveli_and_daman_diu',
    },
    {
      name: 'Delhi',
      value: 'delhi',
    },
    {
      name: 'Ladakh',
      value: 'ladakh',
    },
    {
      name: 'Lakshadweep',
      value: 'lakshadweep',
    },
    {
      name: 'Puducherry',
      value: 'puducherry',
    },
  ];
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          {/* <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div> */}
          <div style={{ marginBottom: "1rem" }} className={s["main-heading-content"]}>
            <div className="main-heading-right">
              <h3>Astrologer ID: {location.state?.data?.astro_id}</h3>
            </div>
            <div className="main-heading-left">
              <div className={s["title"]} onClick={() => navigate(-1)}>
                <BiArrowBack />
                Back
              </div>
            </div>
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <h4 className={s["astro_add_heading"]} > Services</h4>
            <Grid item xs={12}>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  name="is_chat"
                  type="checkbox"
                  checked={formdata.is_chat}
                  value="checked"
                  role="switch"
                  id="is_chat"
                  onChange={(e) => formdataFun(e)}
                />
                <label class="form-check-label" for="is_chat">
                  Chat Available
                </label>
              </div>
              {/* <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  name="is_voice_call"
                  checked={formdata.is_voice_call}
                  type="checkbox"
                  onChange={(e) => formdataFun(e)}
                  role="switch"
                  id="is_voice_call"
                />
                <label class="form-check-label" for="is_voice_call">
                  Voice Call Available
                </label>
              </div> */}
            </Grid>
            <h4 className={s["astro_add_heading"]} > Astrologer Online</h4>
            <Grid item xs={12}>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="is_chat_online"
                  id="is_chat_online"
                  checked={formdata.is_chat_online}
                  onChange={(e) => formdataFun(e)}
                />
                <label class="form-check-label" for="is_chat_online">
                  Chat Online
                </label>
              </div>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="is_expert"
                  role="switch"
                  id="is_expert"
                  checked={formdata.is_expert}
                  onChange={(e) => formdataFun(e)}
                />
                <label class="form-check-label" for="is_expert">
                  Expert Astrologer
                </label>
              </div>
              {/* <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  name="is_voice_call"
                  checked={formdata.is_voice_call}
                  type="checkbox"
                  onChange={(e) => formdataFun(e)}
                  role="switch"
                  id="is_voice_call"
                />
                <label class="form-check-label" for="is_voice_call">
                  Voice Call Available
                </label>
              </div> */}
            </Grid>

            {/* <Grid item xs={12}> */}
            {/* <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={formdata.is_video_call}
                  onChange={(e) => formdataFun(e)}
                  name="is_video_call"
                  id="is_video_call"
                />
                <label class="form-check-label" for="is_video_call">
                  Video Call Available
                </label>
              </div> */}
            {/* <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="is_question"
                  checked={formdata.is_question}
                  id="is_question"
                  onChange={(e) => formdataFun(e)}
                />
                <label class="form-check-label" for="is_question">
                  Question Available
                </label>
              </div> */}
            {/* </Grid> */}
            {/* <Grid item xs={12}> */}
            {/* <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="is_chat_online"
                  id="is_chat_online"
                  checked={formdata.is_chat_online}
                  onChange={(e) => formdataFun(e)}
                />
                <label class="form-check-label" for="is_chat_online">
                  Chat Online
                </label>
              </div> */}
            {/* <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="is_voice_online"
                  checked={formdata.is_voice_online}
                  role="switch"
                  id="is_voice_online"
                  onChange={(e) => formdataFun(e)}
                />
                <label class="form-check-label" for="is_voice_online">
                  Voice Online
                </label>
              </div> */}
            {/* </Grid> */}

            {/* <Grid item xs={12}>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="is_video_online"
                  id="is_video_online"
                  checked={formdata.is_video_online}
                  onChange={(e) => formdataFun(e)}
                />
                <label class="form-check-label" for="is_video_online">
                  Video Online
                </label>
              </div>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="is_expert"
                  role="switch"
                  id="is_expert"
                  checked={formdata.is_expert}
                  onChange={(e) => formdataFun(e)}
                />
                <label class="form-check-label" for="is_expert">
                  Expert Astrologer
                </label>
              </div>
            </Grid> */}
            <h4 className={s["astro_add_heading"]}>About</h4>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input type="text" className="form-control" placeholder=" Name" name="name" value={formdata.name} onChange={(e) => formdataFun(e)} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Display Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Display Name"
                  name="display_name"
                  value={formdata.display_name}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input type="Email" className="form-control" placeholder=" email" name="email" value={formdata.email} onChange={(e) => formdataFun(e)} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Primary Phone Number</label>
                <input type="text" className="form-control" placeholder="Number" name="phone" value={formdata.phone} onChange={(e) => {
                  if (isNaN(e.target.value.trim())) return;
                  else if (e.target.value.trim().includes(".")) return;
                  else if (e.target.value.trim().length > 10) return;
                  else formdataFun(e);
                }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Phone Number 2</label>
                <input type="text" className="form-control" placeholder="Number" name="phone2" value={formdata.phone2}
                  onChange={(e) => {
                    if (isNaN(e.target.value.trim())) return;
                    else if (e.target.value.trim().includes(".")) return;
                    else if (e.target.value.trim().length > 10) return;
                    else formdataFun(e);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Status</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select className="form-control" id="exampleFormControlSelect1" name="status" value={formdata?.status} onChange={(e) => formdataFun(e)}>
                      <option value="true">Active </option>
                      <option value="false">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Language</label>
                <Select closeMenuOnSelect={false} isMulti options={alllanguage} onChange={handleChange} value={languageId} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Category</label>
                <Select closeMenuOnSelect={false} isMulti options={allCategories} onChange={handleChange2} value={categorieId} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Gender</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select className="form-control" id="exampleFormControlSelect1" name="gender" value={formdata.gender} onChange={(e) => formdataFun(e)}>
                      <option value="male">Male </option>
                      <option value="female">Female</option>
                      <option value="others">Others</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">DOB</label>
                <input type="date" className="form-control" placeholder="" name="dob" value={formdata.dob} onChange={(e) => formdataFun(e)} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Experience</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Experience"
                  name="experience"
                  value={formdata.experience}
                  onChange={(e) => {
                    if (isNaN(e.target.value.trim())) return;
                    else if (e.target.value.trim().includes(".")) return;
                    else if (e.target.value.trim().length > 10) return;
                    else formdataFun(e);
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Pincode</label>
                <input type="text" className="form-control" placeholder="Pincode" name="pincode" value={formdata.pincode} onChange={(e) => {
                  if (isNaN(e.target.value.trim())) return;
                  else if (e.target.value.trim().includes(".")) return;
                  // else if (e.target.value.trim().length > 6) return;
                  else formdataFun(e);
                }} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Aadhar Card no</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Adhar number"
                  name="aadhar_no"
                  value={formdata.aadhar_no}
                  onChange={(e) => {
                    if (isNaN(e.target.value.trim())) return;
                    else if (e.target.value.trim().includes(".")) return;
                    else if (e.target.value.trim().length > 12) return;
                    else formdataFun(e);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Qualification</label>
                <div className="mr-2">
                  <input type="text" className="form-control" name="qualification" value={formdata.qualification} onChange={(e) => formdataFun(e)} />
                </div> </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Skill</label>
                <Select closeMenuOnSelect={false} isMulti options={allSkills} onChange={handleChange3} value={skillsId} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Base Number Consultation</label>
                <input type="text" className="form-control" placeholder="" name="order_count" value={formdata.order_count} onChange={(e) => {
                  if (isNaN(e.target.value.trim())) return;
                  else if (e.target.value.trim().includes(".")) return;
                  // else if (e.target.value.trim().length > 6) return;
                  else formdataFun(e);
                }} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Profile Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" placeholder="" accept="image/*" onChange={(e) => setprofile_image(e.target.files[0])} />
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Aadhar Card Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" placeholder="" accept="image/*" onChange={(e) => setaadhar_image(e.target.files[0])} />
                </div>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Address</label>
                <textarea type="text" className="form-control" placeholder="Address" name="address" value={formdata.address} onChange={(e) => formdataFun(e)} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">About</label>
                <textarea type="text" className="form-control" placeholder="About" name="bio" value={formdata.bio} onChange={(e) => formdataFun(e)} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">State</label>
                <div className=" mr-2">
                  <div className="form-group">
                    <select className="form-control" id="exampleFormControlSelect1" name="state" value={formdata.state} onChange={(e) => formdataFun(e)}>
                      <option value="">Select</option>
                      {states_list.map(state => (
                        <option value={state.value}>{state.name}</option>
                      ))}
                      {/* <option value="delhi">Delhi</option> */}
                    </select>
                  </div>
                </div>
              </div>
            </Grid>

            <h4 style={{ marginTop: "1rem" }} className={s["astro_add_heading"]}>Pricing Details</h4>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Chat Price MRP INR (Customer)</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Chat Price INR"
                  name="per_min_chat_mrp"
                  value={formdata.per_min_chat_mrp}
                  onChange={(e) => {
                    if (isNaN(e.target.value.trim())) return;
                    else if (e.target.value.trim().includes(".")) return;
                    else formdataFun(e);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Chat Price SP (Customer)</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Chat Price INR"
                  name="per_min_chat"
                  value={formdata.per_min_chat}
                  onChange={(e) => {
                    if (isNaN(e.target.value.trim())) return;
                    else if (e.target.value.trim().includes(".")) return;
                    else formdataFun(e);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Astrologer Price INR (Per Minute)</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  name="commision"
                  value={formdata.commision}
                  onChange={(e) => {
                    if (isNaN(e.target.value.trim())) return;
                    else if (e.target.value.trim().includes(".")) return;
                    else formdataFun(e);
                  }} />
              </div>
            </Grid>

            <h4 style={{ marginTop: "1rem" }} className={s["astro_add_heading"]}>Bank Details</h4>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Bank</label>
                <input type="text" className="form-control" placeholder="Bank" name="bank_name" value={formdata.bank_name} onChange={(e) => formdataFun(e)} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={s["form-group"]}>
                <label for="exampleInputEmail1">Account Number</label>
                <div className={s["input_eye_parent"]} >
                  <input
                    type={showAccNum ? "text" : "password"}
                    className="form-control"
                    placeholder="Account number"
                    name="bank_account_no"
                    value={formdata.bank_account_no}
                    onChange={(e) => {
                      if (isNaN(e.target.value.trim())) return;
                      else if (e.target.value.trim().includes(".")) return;
                      else formdataFun(e);
                    }}
                  />
                  {showAccNum ? (
                    <BsEyeSlash className={s.cross} style={{ color: "#000" }} onClick={() => setshowAccNum(false)} />
                  ) : (
                    <BsEye className={s.cross} style={{ color: "#000" }} onClick={() => setshowAccNum(true)} />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={s["form-group"]}>
                <label for="exampleInputEmail1">Confirm Account Number</label>
                <div className={s["input_eye_parent"]} >
                  <input
                    type={showCAccNum ? "text" : "password"}
                    className="form-control"
                    placeholder="Account number"
                    name="c_bank_account_no"
                    value={formdata.c_bank_account_no}
                    onChange={(e) => {
                      if (isNaN(e.target.value.trim())) return;
                      else if (e.target.value.trim().includes(".")) return;
                      else formdataFun(e);
                    }}
                  />
                  {showCAccNum ? (
                    <BsEyeSlash className={s.cross} style={{ color: "#000" }} onClick={() => setshowCAccNum(false)} />
                  ) : (
                    <BsEye className={s.cross} style={{ color: "#000" }} onClick={() => setshowCAccNum(true)} />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Account holder name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="bank_account_holder_name"
                  value={formdata.bank_account_holder_name}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">IFSC</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="IFSC code"
                  name="bank_ifsc"
                  value={formdata.bank_ifsc}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">GSTIN</label>
                <input type="text" className="form-control" placeholder="GSTIN" name="gst" value={formdata.gst} onChange={(e) => formdataFun(e)} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">PAN Card Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pancard number"
                  name="pan_no"
                  value={formdata.pan_no}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">TDS Rate</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="TDS Rate"
                  name="tds_rate"
                  value={formdata.tds_rate}
                  onChange={(e) => {
                    if (isNaN(e.target.value.trim())) return;
                    else if (e.target.value.trim().includes(".")) return;
                    else formdataFun(e);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select PAN Card Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" placeholder="" accept="image/*" onChange={(e) => setpan_image(e.target.files[0])} />
                </div>
              </div>
            </Grid>

            {/* <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">Country</label>
                <input type="text" className="form-control" placeholder="Country" />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">State</label>
                <input type="text" className="form-control" placeholder="State" />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">City</label>
                <input type="text" className="form-control" placeholder="City" />
              </div>
            </Grid> */}

            {/* <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">Password</label>
                <input type="text" className="form-control" placeholder="" name="password" value={formdata.password} onChange={(e) => formdataFun(e)} />
              </div>
            </Grid> */}


            {/* <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">Chat Price INR (Customer)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Chat Price INR"
                  name="per_min_chat"
                  value={formdata.per_min_chat}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid> */}

            {/* <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">Audio Price INR</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Audio Price INR"
                  name="per_min_voice_call"
                  value={formdata.per_min_voice_call}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">Video Price INR</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Video Price INR"
                  name="per_min_video_call"
                  value={formdata.per_min_video_call}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">Question Price INR</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Question Price INR"
                  name="per_question_price"
                  value={formdata.per_question_price}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">Chat Price USD</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Chat Price USD"
                  name="per_min_chat_usd"
                  value={formdata.per_min_chat_usd}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">Audio Price USD</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Audio Price USD"
                  name="per_min_voice_call_usd"
                  value={formdata.per_min_voice_call_usd}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">Video Price USD</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Video Price USD"
                  name="per_min_video_call_usd"
                  value={formdata.per_min_video_call_usd}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={3}>
              <div className="form-group">
                <label for="exampleInputEmail1">Question Price USD</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Question Price USD"
                  name="per_question_price_usd"
                  value={formdata.per_question_price_usd}
                  onChange={(e) => formdataFun(e)}
                />
              </div>
            </Grid> */}

            {/* <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Bio</label>
                <input type="text" className="form-control" placeholder="Bio" onChange={(e) => formdataFun(e)} />
              </div>
            </Grid> */}
            {/* <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Report Type</label>
                <input type="text" className="form-control" placeholder="Report" />
              </div>
            </Grid> */}
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => addastrologer_function()}>
            <Custombutton>{isloading ? <CircularProgress style={{ width: "20px", height: "20px", color: "#fff" }} /> : "Submit"}</Custombutton>

          </div>
        </Card>
      </div>
    </>
  );
};

export default AstrologerAdd;
