import React, { useState, useEffect } from "react";
import s from "./testimonial.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { createRole_api, role_update_api } from "../api/role";
import { addTestimonial_api, updateTestimonial_api } from "../api/testimonial";
const TestimonialAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState("");
  const [file, setfile] = useState(null);
  const [rating, setrating] = useState("");
  const [feedback, setfeedback] = useState("");
  const [age, setage] = useState("")
  const [userlocation, setuserlocation] = useState("")
  const pagetype = location.state.pagetype;
  console.log(location);
  useEffect(() => {
    setname(location?.state?.data?.name);
    setrating(location?.state?.data?.rating);
    setfeedback(location?.state?.data?.feedback);
    setage(location?.state?.data?.user_age);
    setuserlocation(location?.state?.data?.user_location);
  }, [location]);

  const role_function = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("feedback", feedback);
      fd.append("rating", rating);
      fd.append("user_image", file);
      fd.append("user_age", age);
      fd.append("user_location", userlocation);
      try {
        let res = await addTestimonial_api(fd);
        if (res.data.status) {
          navigate("/testimonial-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("feedback", feedback);
      fd.append("rating", rating);
      fd.append("user_image", file);
      fd.append("id", location?.state?.data?.id);
      fd.append("user_age", age);
      fd.append("user_location", userlocation);
      try {
        let res = await updateTestimonial_api(fd);
        if (res.data.status) {
          console.log(res);
          navigate("/testimonial-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">User Name</label>
                <input type="text" className="form-control" name="name" value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">User Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Rating</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select className="form-control" id="exampleFormControlSelect1" value={rating} onChange={(e) => setrating(e.target.value)} name="role">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">User Age</label>
                <div className="mr-2">
                  <input type="text" className="form-control" value={age} onChange={(e) => setage(e.target.value)} placeholder="Age" />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Location</label>
                <div className="mr-2">
                  <input type="text" className="form-control" value={userlocation} onChange={(e) => setuserlocation(e.target.value)} placeholder="Location" />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Feedback</label>
                <textarea
                  type="text"
                  className="form-control"
                  rows={3}
                  name="name"
                  value={feedback}
                  onChange={(e) => setfeedback(e.target.value)}
                  placeholder="Feedback"
                />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => role_function()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default TestimonialAdd;
