import React from "react";
import AllSkill from "../components/Skill/AllSkill";

export const SkillList = () => {
  return (
    <div>
      <AllSkill />
    </div>
  );
};
