import React, { useState, useEffect } from "react";
import s from "../Astrologer/astrologer.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addAstrologerGallery_api } from "../api/astrologer";
const AstrologerGalleryAdd = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [imageType, setimageType] = useState("image")
    const [file, setfile] = useState(null);
    console.log("add gallery => location.state", location.state.data)

    const pagetype = location?.state?.pagetype;
    useEffect(() => {
        // settype(location?.state?.data?.type);
    }, [location]);

    const Category_function = async () => {

        if (pagetype == "Add") {
            const fd = new FormData();
            fd.append("astro_id", location.state.data.id);
            fd.append("type", imageType);
            fd.append("media", file);
            try {
                let res = await addAstrologerGallery_api(fd);
                if (res.data.status) {
                    navigate("/astrologer-gallery", { state: location.state });
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }

        // if (pagetype == "Edit") {
        //     const fd = new FormData();
        //     fd.append("id", location?.state?.data?.id);
        //     fd.append("type", type);
        //     fd.append("banner_image", file);
        //     fd.append("url", url);
        //     try {
        //         let res = await updateBanner_api(fd);
        //         if (res.data.status) {
        //             navigate("/banner-list");
        //             notificationHandler({ type: "success", msg: res.data.message });
        //         } else {
        //             notificationHandler({ type: "success", msg: res.data.message });
        //         }
        //     } catch (error) {
        //         notificationHandler({ type: "danger", msg: error.message });
        //         console.log(error);
        //     }
        // }
    };

    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            {/* <div className="form-group">
                                <label for="exampleInputEmail1">Type</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={imageType}
                                    onChange={(e) => setimageType(e.target.value)}
                                />
                            </div> */}
                            <div className="form-group">
                                <label for="exampleInputEmail1">Gallery Type</label>
                                <select className="form-control" value={imageType} onChange={(e) => setimageType(e.target.value)}>
                                    <option value="image">Image</option>
                                    <option value="video">Video</option>
                                </select>
                            </div>
                        </Grid>
                        {imageType === "image" ? (
                            <Grid item xs={6}>
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Image  (only png, jpg, jpeg formats supported)</label>
                                    <div className="mr-2">
                                        <input
                                            type="file"
                                            className="form-control"
                                            accept="image/*"
                                            onChange={(e) => setfile(e.target.files[0])} />
                                    </div>
                                </div>
                            </Grid>
                        ) : (
                            <Grid item xs={6}>
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Video (only mp4, mkv, webm formats supported)</label>
                                    <div className="mr-2">
                                        <input
                                            type="file"
                                            className="form-control"
                                            accept="video/mp4, video/mkv, video/webm"
                                            onChange={(e) => setfile(e.target.files[0])} />
                                    </div>
                                </div>
                            </Grid >
                        )}
                    </Grid >

                    <div className={s["form-login-btn"]} onClick={() => Category_function()}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card >
            </div >
        </>
    );
};

export default AstrologerGalleryAdd;
