import React from "react";
import LanguageList from "../components/Language/LanguageList";

const Language = () => {
  return (
    <>
      <LanguageList />
    </>
  );
};

export default Language;
