import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const addAdmin = getBaseUrl() + "admin/addAdmin";
const admins_list = getBaseUrl() + "admin/getAdmins";
const edit_admins = getBaseUrl() + "admin/updateAdmin";
const delete_admins = getBaseUrl() + "admin/delete";
const status_update_admin = getBaseUrl() + "admin/updateAdmin";
const getroll_admin = getBaseUrl() + "role";

export const getroll_admin_api = async (data) => {
  let config = {
    method: "get",
    url: getroll_admin,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const update_admin_api = async (data) => {
  let config = {
    method: "PATCH",
    url: edit_admins,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const status_admin_api = async (data) => {
  let config = {
    method: "PATCH",
    url: status_update_admin,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const delete_admin_api = async (data) => {
  let config = {
    method: "DELETE",
    url: delete_admins,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const create_admin_api = async (data) => {
  let config = {
    method: "post",
    url: addAdmin,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllAdmin = async (data) => {
  let config = {
    method: "get",
    url: admins_list + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default fetchAllAdmin;
