import React, { useState, useEffect } from "react";
import s from "./offer.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addBanner_api, updateBanner_api } from "../api/banner";
import { addNews_api } from "../api/newsAPI";
import { addOffer_api, updateOffer_api } from "../api/offerAPI";
import { RxCross2 } from "react-icons/rx";

const AddOffer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log("location", location.state)
    const pagetype = location.state.pagetype;

    const [offerAmt, setofferAmt] = useState("");
    const [offerType, setofferType] = useState("percent");
    const [baseAmt, setbaseAmt] = useState("");
    const [offerValidity, setofferValidity] = useState("");
    const [startDate, setstartDate] = useState("");
    const [endDate, setendDate] = useState("");
    const [selectedUseType, setselectedUseType] = useState("-1");
    const [useCount, setuseCount] = useState("-1");
    const [priority, setpriority] = useState("");
    const [tags, settags] = useState([]);
    const [tag, settag] = useState("");

    const [error, seterror] = useState({})

    useEffect(() => {
        if (location.state.data) {
            setofferAmt(location?.state?.data?.offer_amt);
            setofferType(location?.state?.data?.offer_type);
            setbaseAmt(location?.state?.data?.base_amt);
            setofferValidity(location?.state?.data?.validity);
            setstartDate(new Date(new Date(location?.state?.data?.start_date).toString().split('GMT')[0]+' UTC').toISOString().split('.')[0]);
            setendDate(new Date(new Date(location?.state?.data?.end_date).toString().split('GMT')[0]+' UTC').toISOString().split('.')[0]);
            setpriority(location?.state?.data?.priority);

            setselectedUseType(location?.state?.data?.use_count != "-1" ? "limited" : location?.state?.data?.use_count);
            setuseCount(location?.state?.data?.use_count);
            settags(location?.state?.data?.tags.split(","));
        }
    }, [location]);
    console.log("selectedUseType", selectedUseType)

    const Category_function = async () => {
        let tempErrors = {}
        if (!baseAmt) tempErrors.baseAmt = "Base Amount is Required!";
        if (!offerAmt) tempErrors.offerAmt = "Offer Amount is Required!";
        if (!offerType) tempErrors.offerType = "Offer Type is Required!";
        if (!startDate) tempErrors.startDate = "Start Date is Required!";
        if (new Date(startDate) > new Date(endDate)) tempErrors.startDate = "Start Date Should Be Less Than End Date!";
        if (!endDate) tempErrors.endDate = "End Date is Required!";
        if (!offerValidity) tempErrors.offerValidity = "Offer Validity is Required!";
        if (!priority) tempErrors.priority = "Priority is Required!";

        seterror(tempErrors);
        if (Object.keys(tempErrors).length) return;

        let tempData = {
            offer_amt: offerAmt,
            offer_type: offerType,
            base_amt: baseAmt,
            validity: offerValidity,
            start_date: new Date(startDate).toISOString(),
            end_date: new Date(endDate).toISOString(),
            use_count: useCount,
            priority: priority,
            tags: tags.join(",")
        }

        if (pagetype == "Add") {
            let res = await addOffer_api(tempData);
            if (res.data.status) {
                navigate("/offers_list");
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                notificationHandler({ type: "success", msg: res.data.message });
                console.log("Some Error!")
            }
        }
        if (pagetype == "Edit") {
            tempData = { ...tempData, id: location?.state?.data?.id }
            let res = await updateOffer_api(tempData);
            if (res.data.status) {
                navigate("/offers_list");
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                notificationHandler({ type: "success", msg: res.data.message });
                console.log("Some Error!")
            }
        }
    };

    const manageTagsFunc = () => {
        settags([...tags, tag])
        settag("")
    }
    const removeTagFunc = (tag) => {
        const filtered = tags.filter((oldTag) => oldTag !== tag);
        settags(filtered)
    }

    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Recharge Amount</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={baseAmt}
                                        onChange={(e) => {
                                            if (isNaN(e.target.value.trim())) return;
                                            else if (e.target.value.trim().includes(".")) return;
                                            else setbaseAmt(e.target.value)
                                        }} />
                                </div>
                                {error.baseAmt !== "" && <div style={{ width: "98%", fontSize: "0.9rem", color: "red" }} >{error.baseAmt}</div>}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Offer Amount</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={offerAmt}
                                        onChange={(e) => {
                                            if (isNaN(e.target.value.trim())) return;
                                            else if (e.target.value.trim().includes(".")) return;
                                            else setofferAmt(e.target.value)
                                        }} />
                                </div>
                                {error.offerAmt !== "" && <div style={{ width: "98%", fontSize: "0.9rem", color: "red" }} >{error.offerAmt}</div>}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Offer Type</label>
                                <div className="mr-2">
                                    <div className="form-group">
                                        <select value={offerType} onChange={(e) => setofferType(e.target.value)} className="form-control">
                                            <option value="percent">Percentage</option>
                                            <option value="fix">Fixed</option>
                                        </select>
                                    </div>
                                </div>
                                {error.offerType !== "" && <div style={{ width: "98%", fontSize: "0.9rem", color: "red" }} >{error.offerType}</div>}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Validity (in Days)</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={offerValidity}
                                        onChange={(e) => {
                                            if (isNaN(e.target.value.trim())) return;
                                            else if (e.target.value.trim().includes(".")) return;
                                            else setofferValidity(e.target.value)
                                        }} />
                                </div>
                                {error.offerValidity !== "" && <div style={{ width: "98%", fontSize: "0.9rem", color: "red" }} >{error.offerValidity}</div>}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Start Time</label>
                                <div className="mr-2">
                                    <input
                                        type="datetime-local"
                                        className="form-control"
                                        value={startDate}
                                        onChange={(e) => setstartDate(e.target.value)} />
                                </div>
                                {error.startDate !== "" && <div style={{ width: "98%", fontSize: "0.9rem", color: "red" }} >{error.startDate}</div>}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">End Time</label>
                                <div className="mr-2">
                                    <input
                                        type="datetime-local"
                                        className="form-control"
                                        value={endDate}
                                        onChange={(e) => setendDate(e.target.value)} />
                                </div>
                                {error.endDate !== "" && <div style={{ width: "98%", fontSize: "0.9rem", color: "red" }} >{error.endDate}</div>}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Uses</label>
                                <div className="mr-2">
                                    <div className="form-group">
                                        <select value={selectedUseType} onChange={(e) => {
                                            setuseCount(e.target.value)
                                            setselectedUseType(e.target.value)
                                        }}
                                            className="form-control">
                                            <option value="-1">Multiple</option>
                                            <option value="limited">Limited</option>
                                        </select>
                                    </div>
                                    {/* {error.useCount !== "" && <div style={{ width: "98%", fontSize: "0.9rem", color: "red" }} >{error.useCount}</div> } */}
                                </div>
                            </div>
                        </Grid>
                        {selectedUseType === "limited" && (
                            <Grid item xs={6}>
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Use Count</label>
                                    <div className="mr-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={useCount === "limited" ? "" : useCount}
                                            onChange={(e) => {
                                                if (isNaN(e.target.value.trim())) return;
                                                else if (e.target.value.trim().includes(".")) return;
                                                else setuseCount(e.target.value)
                                            }} />
                                    </div>
                                    {error.useCount !== "" && <div style={{ width: "98%", fontSize: "0.9rem", color: "red" }} >{error.useCount}</div>}
                                </div>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Priority</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={priority}
                                        onChange={(e) => {
                                            if (isNaN(e.target.value.trim())) return;
                                            else setpriority(e.target.value)
                                        }} />
                                </div>
                                {error.priority !== "" && <div style={{ width: "98%", fontSize: "0.9rem", color: "red" }} >{error.priority}</div>}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Tags</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={tag}
                                        onChange={(e) => settag(e.target.value)}
                                        onKeyUp={(e) => { if (e.keyCode === 13) manageTagsFunc() }}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ marginLeft: selectedUseType === "limited" && "51.5%" }} className={s.offer_tags_container} >
                                {tags?.map(tag => (
                                    <div className={s.offer_tag} >
                                        <span>{tag}</span>
                                        <span>
                                            <RxCross2
                                                onClick={() => removeTagFunc(tag)}
                                                style={{
                                                    color: "var(--main-color)",
                                                    marginLeft: "0.3rem",
                                                }}
                                            />
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>

                    <div className={s["form-login-btn"]} onClick={() => Category_function()}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AddOffer;
