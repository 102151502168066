import React from "react";
import AddAdmin from "../components/Admin/AddAdmin";
const Adminadd = () => {
  return (
    <>
      <AddAdmin />
    </>
  );
};

export default Adminadd;
