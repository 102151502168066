import React from "react";
import AstrologerAdd from "../components/Astrologer/AstrologerAdd";

const AddAstrologer = () => {
  return (
    <div>
      <AstrologerAdd />
    </div>
  );
};

export default AddAstrologer;
