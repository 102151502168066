import React, { useState, useEffect } from "react";
import s from "./user.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Avatar, Card, Grid, Menu, MenuItem } from "@mui/material";
import { user_update_api } from "../api/user";

const AddUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState("");
  const [dob, setdob] = useState("");
  const [email, setemail] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [userlocation, setuserlocation] = useState("");
  const [place_of_birth, setplace_of_birth] = useState("");
  const [number, setnumber] = useState("");
  const [gender, setgender] = useState("Male");
  const [userId, setuserId] = useState("");
  const [profileImg, setprofileImg] = useState(null);

  const pagetype = location.state.pagetype;
  // console.log(location);
  useEffect(() => {
    setname(location?.state?.data?.user_name);
    setemail(location?.state?.data?.email);
    setdob(location?.state?.data?.dob);
    setgender(location?.state?.data?.gender);
    setnumber(location?.state?.data?.phone);
    setdob(location?.state?.data?.dob);
    setprofileImg(location?.state?.data?.profile_image);
  }, [location]);

  const create_user = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("gender", gender);
      fd.append("dob", dob);
      fd.append("phone", number);
      fd.append("email", email);
      fd.append("place_of_birth", place_of_birth);
      fd.append("location", userlocation);
      fd.append("profile_image", profileImg);
      try {
        let res = await user_update_api(fd);
        if (res.data.status) {
          // console.log(res);
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("id", location?.state?.data?.id);
      fd.append("name", name);
      fd.append("gender", gender);
      fd.append("dob", dob);
      fd.append("phone", number);
      fd.append("email", email);
      fd.append("place_of_birth", place_of_birth);
      fd.append("location", userlocation);
      fd.append("profile_image", profileImg);

      try {
        let res = await user_update_api(fd);
        if (res.data.status) {
          // console.log(res);
          navigate("/users");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className={s["admin_container"]}>
        <div className={s["main-heading-content"]}>
          <div className="main-heading-right">
            <h3>User</h3>
          </div>
          <div className="main-heading-left">
            <div className={s["title"]} onClick={() => navigate(-1)}>
              <BiArrowBack />
              Back
            </div>
          </div>
        </div>
        <Card className={s["card-header"]}>
          <div className={s["title"]}>Profile Details</div>
          <div className={s["user_profile_pic"]}>
            <Avatar src={profileImg} sx={{ width: "7rem", height: "7rem" }} />
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input type="text" className="form-control" name="name" value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input type="text" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" name="email" placeholder="Email" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Mobile Number</label>
                <input type="nember" value={number} onChange={(e) => {
                  if (isNaN(e.target.value.trim())) return;
                  else if (e.target.value.trim().includes(".")) return;
                  else if (e.target.value.trim().length > 10) return;
                  else setnumber(e.target.value)
                }} className="form-control" name="number" placeholder="Number" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Place of Birth</label>
                <input
                  type="text"
                  value={place_of_birth}
                  onChange={(e) => setplace_of_birth(e.target.value)}
                  className="form-control"
                  name="place_of_birth"
                  placeholder="Place of Birth"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Gender</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select className="form-control" value={gender} onChange={(e) => setgender(e.target.value)} id="exampleFormControlSelect1" name="gender">
                      <option value="Male">Male </option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Date of Birth</label>
                <input
                  type="date"
                  name="dob"
                  value={dob}
                  onChange={(e) => setdob(e.target.value)}
                  max={new Date().toISOString().slice(0, 10)}
                  className="form-control"
                  placeholder="Enter DOB"
                />
              </div>
            </Grid>
            {/* <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="  mr-2">
                  <input type="file" accept="image/*" onChange={(e) => setprofileImg(e.target.files[0])} className="form-control" />
                </div>
              </div>
            </Grid> */}
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => create_user()}>
            <Custombutton>Submit </Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddUser;
