import React, { useState, useEffect } from "react";
import s from "./news.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addBanner_api, updateBanner_api } from "../api/banner";
import { addNews_api } from "../api/newsAPI";

const AddNews = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pagetype = location.state.pagetype;

    const [title, settitle] = useState("")
    const [des, setdes] = useState("")
    const [file, setfile] = useState(null);
    const [url, seturl] = useState("");

    useEffect(() => {
        if (location.state) {
            // settype(location?.state?.data?.type);
        }
    }, [location]);

    const Category_function = async () => {
        // let img = new Image()
        // img.src = URL.createObjectURL(file)
        // img.onload = () => {
        //   console.log("width & height is ", img.width, img.height);
        // }

        if (pagetype == "Add") {
            const fd = new FormData();
            fd.append("title", title);
            fd.append("description", des);
            fd.append("news_image", file);
            fd.append("url", url);
            return console.log(fd)

            let res = await addNews_api(fd);
            if (res.data.status) {
                navigate("/news_list");
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                notificationHandler({ type: "success", msg: res.data.message });
                console.log("Some Error!")
            }
        }
        if (pagetype == "Edit") {
            const fd = new FormData();
            fd.append("title", title);
            fd.append("description", des);
            fd.append("news_image", file);
            fd.append("url", url);
            return console.log(fd)

            let res = await updateBanner_api(fd);
            if (res.data.status) {
                navigate("/banner-list");
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                notificationHandler({ type: "success", msg: res.data.message });
                console.log("Some Error!")
            }
        }
    };

    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Title</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={title}
                                        onChange={(e) => settitle(e.target.value)} />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Description</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={des}
                                        onChange={(e) => setdes(e.target.value)} />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Select Image</label>
                                <div className="mr-2">
                                    <input
                                        type="file"
                                        className="form-control"
                                        accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                                </div>
                            </div>  
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Redirect URL</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={url}
                                        onChange={(e) => seturl(e.target.value)} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div className={s["form-login-btn"]} onClick={() => Category_function()}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AddNews;
