import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";
let local = "http://localhost:5007/"

export const getOffers_api = async (data) => {
    let config = {
        method: "get",
        url: getBaseUrl() + "admin/offer" + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const addOffer_api = async (data) => {
    let config = {
        method: "post",
        url: getBaseUrl() + "admin/offer",
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data,
    };

    try {
        return await axios(config);
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const updateOffer_api = async (data) => {
    let config = {
        method: "patch",
        url: getBaseUrl() + `admin/offer/${data.id}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data,
    };

    try {
        return await axios(config);
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const updateOfferIsDefault_api = async (id) => {
    let config = {
        method: "patch",
        url: getBaseUrl() + `admin/defaultOffer/${id}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };

    try {
        return await axios(config);
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const deleteOffer_api = async (id) => {
    let config = {
        method: "delete",
        url: getBaseUrl() + `admin/offer/${id}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };

    try {
        return await axios(config);
    } catch (error) {
        console.log(error);
        return error.response;
    }
};