import React, { useState, useEffect } from "react";
import s from "./language.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addLanguage_api, updateLanguage_api } from "../api/language";
const AddLanguage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState("");
  const pagetype = location.state.pagetype;
  console.log(location);
  useEffect(() => {
    setname(location?.state?.data?.lang_name);
  }, [location]);

  const create_user = async () => {
    if (pagetype == "Add") {
      let temp = {
        lang_name: name,
      };
      try {
        let res = await addLanguage_api(temp);
        if (res.data.status) {
          navigate("/language");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      let temp = {
        lang_name: name,
        id: location?.state?.data?.id,
      };
      try {
        let res = await updateLanguage_api(temp);
        if (res.data.status) {
          navigate("/language");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input type="text" className="form-control" name="name" value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => create_user()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddLanguage;
