import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const getrole = getBaseUrl() + "role";
const createRole = getBaseUrl() + "role/createRole";
const updaterole = getBaseUrl() + "role/update";
const deleteRole = getBaseUrl() + "role/delete";
const status_update = getBaseUrl() + "role/update";

export const createRole_api = async (data) => {
  let config = {
    method: "post",
    url: createRole,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const role_update_api = async (data) => {
  let config = {
    method: "PATCH",
    url: updaterole,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const role_delete_api = async (data) => {
  let config = {
    method: "DELETE",
    url: deleteRole,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const role_status_api = async (data) => {
  let config = {
    method: "PATCH",
    url: status_update,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllrole = async (data) => {
  let config = {
    method: "get",
    url: getrole + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
