import "./ViewInvoice.css"
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateInvoice, getInvoice } from '../api/astrologer';
import s from "./astrologer.module.css";
import Loder from '../../Loder/Loder';
import { notificationHandler } from "../../utils/Notification";
import { Dialog, DialogActions, DialogTitle, DialogContent } from "@mui/material";

const ViewInvoice = () => {
    const navigate = useNavigate()
    const location = useLocation();
    console.log("location", location.state);
    const [isLoading, setisLoading] = useState(false);
    const [invoiceData, setinvoiceData] = useState();
    const [invoiceConfirmPopup, setinvoiceConfirmPopup] = useState(false)

    useEffect(() => {
        if (location.state.data) return setinvoiceData(location.state.data)
        getInvoiceFunc();
    }, []);
    console.log("invoiceData", invoiceData);

    async function getInvoiceFunc() {
        setisLoading(true);
        let temp = location.state;
        temp.start_date = undefined;
        let res = await getInvoice(temp);
        if (res.data.status) {
            setinvoiceData(res.data.data);
        } else {
            console.log("status false!");
        }
        setisLoading(false);
    }

    const generateInvoiceFunc = async () => {
        let temp = location.state;
        temp.start_date = undefined;
        let res = await generateInvoice(temp);
        if (res.data.status) {
            notificationHandler({ type: "success", msg: res.data.message });
            console.log("Invoice generated.");
            navigate("/astrologer-invoices", { state: { data: { id: location.state.astro_id } } })
        } else {
            console.log("Some Error!");
        }
        setisLoading(false);
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Invoice</h3>
                    </div>
                    <div className={s["user-list-search"]}>
                    </div>
                </div>
                <div className="beat_left">
                    {!location.state?.data && (
                        <div onClick={() => setinvoiceConfirmPopup(true)} className={s["filter_btn"]} >Generate Invoice</div>
                    )}
                </div>
            </div>

            {/* invoice */}
            {invoiceData && (
                <div className="invoice">
                    <h4>Tax Invoice</h4>
                    <p>(Original for recipient)</p>
                    <p>{invoiceData?.recipient_name}</p>
                    <p>Supplier GSTIN: {invoiceData?.recipient_gstin || 'NA'}</p>
                    <p>Address: {invoiceData?.recipient_address} {invoiceData.recipient_state.split("_").map(str => str.charAt(0).toLocaleUpperCase() + str.slice(1)).join(" ")}</p>
                    <div className="info-box">
                        <table className="addr-info">
                            <tr>
                                <td colSpan="2">Customer Address:</td>
                            </tr>
                            <tr>
                                <th colSpan="2" className="addr">
                                    {invoiceData?.supplier_address}
                                </th>
                            </tr>
                            <tr>
                                <td>Place of Supply:</td>
                                <th className="bdr-b">Karnataka</th>
                            </tr>
                        </table>
                        <table className="invoice-info">
                            <tr>
                                <td className="mr">Recipient GSTIN: </td>
                                <td className="bdr-b">{invoiceData?.supplier_gstin}</td>
                            </tr>
                            <tr>
                                <td className="mr">Invoice No.: </td>
                                <td className="bdr-b">{invoiceData?.invoice_no}</td>
                            </tr>
                            <tr>
                                <td className="mr">Invoice Date: </td>
                                <td className="bdr-b">
                                    {new Intl.DateTimeFormat('en', { day: '2-digit', month: 'short', year: 'numeric' }).format(
                                        new Date(invoiceData?.createdAt || invoiceData?.created_at)
                                    )}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <table className="inv-table" border="1">
                        <tr>
                            <th rowSpan="2">Description</th>
                            <th rowSpan="2">Total</th>
                            <th rowSpan="2">Discount</th>
                            <th rowSpan="2">Taxable Value</th>
                            <th colSpan="2">SGST</th>
                            <th colSpan="2">CGST</th>
                            <th colSpan="2">IGST</th>
                        </tr>
                        <tr>
                            <th>Rate</th>
                            <th>Amt</th>
                            <th>Rate</th>
                            <th>Amt</th>
                            <th>Rate</th>
                            <th>Amt</th>
                        </tr>
                        <tr>
                            <td>Net earnings of transactions from dt:  {new Intl.DateTimeFormat('en', { day: '2-digit', month: 'short', year: 'numeric' }).format(
                                new Date(invoiceData?.start_date)
                            )} to dt:  {new Intl.DateTimeFormat('en', { day: '2-digit', month: 'short', year: 'numeric' }).format(
                                new Date(invoiceData?.end_date)
                            )}</td>
                            <td>₹{invoiceData.total_amount}</td>
                            <td>₹0.00</td>
                            <td>₹{invoiceData.taxable_amount}</td>
                            <td>{invoiceData.sgst}%</td>
                            <td>₹{invoiceData.taxable_amount * invoiceData.sgst * 0.01}</td>
                            <td>{invoiceData.cgst}%</td>
                            <td>₹{invoiceData.taxable_amount * invoiceData.cgst * 0.01}</td>
                            <td>{invoiceData.igst}%</td>
                            <td>₹{invoiceData.taxable_amount * invoiceData.igst * 0.01}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>₹{invoiceData.total_amount}</td>
                            <td>₹0.00</td>
                            <td>₹{invoiceData.taxable_amount}</td>
                            <td></td>
                            <td>₹{invoiceData.taxable_amount * invoiceData.sgst * 0.01}</td>
                            <td></td>
                            <td>₹{invoiceData.taxable_amount * invoiceData.cgst * 0.01}</td>
                            <td></td>
                            <td>₹{invoiceData.taxable_amount * invoiceData.igst * 0.01}</td>
                        </tr>
                        <tr>
                            <td>Total Tax</td>
                            <td colSpan="9">₹{invoiceData.total_tax}</td>
                        </tr>
                        <tr>
                            <td>Invoice Amount</td>
                            <td colSpan="9">₹{invoiceData.invoice_amount}</td>
                        </tr>
                        <tr>
                            <td>TDS Rate</td>
                            <td colSpan="9">{invoiceData.recipient_tds_rate}%</td>
                        </tr>
                        <tr>
                            <td>TDS</td>
                            <td colSpan="9">₹{invoiceData.tds_amount}</td>
                        </tr>
                        <tr>
                            <td>Amount to be credited</td>
                            <td colSpan="9">₹{invoiceData.settlement_amount}</td>
                        </tr>
                    </table>
                    <table className="other-info">
                        <p className="b mt">Other Details:</p>
                        <tr>
                            <td>HSN/SAC</td>
                            <td className="bdr-b">998399</td>
                        </tr>
                        <tr>
                            <td>Whether tax is payable on reverse charge basis</td>
                            <td className="bdr-b">No</td>
                        </tr>
                        <tr>
                            <td>PAN Number</td>
                            <td className="bdr-b">{invoiceData.recipient_pan}</td>
                        </tr>
                    </table>
                    <div className="signature_sign">
                        <div className="fs-20">{invoiceData.recipient_name}</div>
                        <div className="bdr-t">Digitally signed by: {invoiceData.recipient_name}</div>
                    </div>
                    {/* <p className="sign">Digitally signed by: {invoiceData.recipient_name}</p> */}
                </div>
            )}

            <Dialog open={invoiceConfirmPopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setinvoiceConfirmPopup(false)}>
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to generate invoice?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div className={s.employee_gl_popup} onClick={() => setinvoiceConfirmPopup(false)}>
                            Cancel
                        </div>
                        <div className={s.employee_gl_popup_del} onClick={() => generateInvoiceFunc()}>
                            Yes
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
            <Loder loading={isLoading} />
        </div >
    )
}

export default ViewInvoice