import React from "react";
import AstrologerList from "../components/Astrologer/AstrologerList";

const Astrologer = () => {
  return (
    <div>
      <AstrologerList />
    </div>
  );
};

export default Astrologer;
